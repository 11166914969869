:root {
  --link-font-size: 0.6rem;
}

#App-Link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* Gap here will control the spacing between icons and text */
  gap: calc(var(--link-font-size) / 9);
  align-items: center;
  shape-outside: ellipse(100% 12px);
  width: fit-content;
  font-size: var(--link-font-size);
  white-space: nowrap;
  color: var(--mrr-dark-blue) !important;
}

#App-Link:hover {
  color: var(--mrr-light-blue) !important;
}

#App-Link-Icon {
  font-size: var(--link-font-size);
}
