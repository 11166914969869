.Listings-List-Main-header {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  gap: 10px;
}
.Listings-Filter-List {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  overflow: scroll;
  gap: 5px;
}
.Group-Container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.hidden-group {
  display: none;
}
