:root {
  --AppTypography-default-text-color: #666666;
}

.AppTypographyHeader {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 12px !important; /* line-height here is being used to control the gap between header and text. !!!!! Not the gap between items*/
}

.AppTypographyHeader-Icon {
  font-size: inherit !important;
}

.AppTypographyBody {
  color: var(--AppTypography-default-text-color);
  font-size: 14px !important;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
}

.SubTitle-Text {
  font-size: 14px !important;
  font-weight: 600px !important;
  line-height: 14px !important;
}

.MainHeader-Text {
  font-size: 16px;
  line-height: 18px;
  font-weight: 800;
}
