:root {
  --min-row-height: 45px;
  --box-padding: 12px;
  --box-border-radius: 10px;
  --box-margin-bottom: 8px;
}
.OuterBox_Paper {
  position: relative;
  width: auto !important;
  height: fit-content !important;
  box-sizing: border-box;
  margin-bottom: var(--box-margin-bottom);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--box-border-radius) !important;
  scroll-behavior: smooth !important;
}
.OuterBox_Paper::before {
  content: "";
  height: 5px !important;
  background: var(--mrr-dark-blue);
  width: 100%;
  border-radius: var(--box-border-radius) var(--box-border-radius) 0 0;
}
.OuterBox_Paper:hover::before,
.OuterBox_Paper:active::before {
  background: var(--mrr-light-blue);
}
.BoxContent {
  padding: calc(var(--box-padding) - 5px) var(--box-padding) var(--box-padding)
    var(--box-padding);
  display: flex;
  flex-direction: column;
  width: 100% !important;
}
.BoxContent_Title {
  line-height: 1.25rem !important;
  font-weight: 600;
}
.BoxContent_Title_Chip {
  height: 1.25rem !important;
}
.BoxContent_Title-Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--box-padding);
  max-width: inherit;
  width: inherit;
}

.BoxContent_Title-Container-bb {
  border-bottom: 1px solid lightgray;
  margin-bottom: 10px; /* If there is a bottom border under the title than we want to add a little bit of spacing*/
}

.Title-Chip_Container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
