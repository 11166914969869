:root {
  --icon-size: 16px;
  --task-card-footer-height: 65px;
}

.Task-Card-Container {
  padding: 0 10px;
  overflow-x: clip;
  overflow-y: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  letter-spacing: 0;
}

.Task-Drawer-Container .Task-Card-Date-Footer {
  padding-bottom: calc(var(--task-card-footer-height) + 10px) !important;
}

.Flex-Between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}

.Task-Card-Row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

/* .priority-pte-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  flex-wrap: nowrap;
} */

.Split-Title-Row {
  display: flex;
  justify-content: space-between;
}

.Task-Icon-Section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 2px;
}

#Task-Card-Icon {
  font-size: var(--icon-size) !important;
  object-fit: contain;
}

#Task-Card-Icon > * {
  top: 5px;
  right: 5px;
  position: absolute;
  animation: bounce 4s infinite;
}

.Top-Row-Title {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    /* font-size: calc(var(--icon-size) * 1.5); */
    transform: translateY(calc(var(--icon-size) / 3));
  }
  60% {
    /* font-size: calc(var(--icon-size) * 1.5); */
    transform: translateY(calc(var(--icon-size) / 3));
  }
}

.Task-Card-Item-Top-Row {
  justify-content: space-between;
  gap: 10px;
}

.Task-Card-Item-Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Task-Card-Item-Border {
  /* margin-top: 10px; */
  border-top: 1px solid lightgray;
  /* padding-top: 5px; */
  margin-bottom: 0px !important;
}

.Task-Card-Item-Row {
  display: flex;
  /* flex-wrap: nowrap; */
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}

.No-Margin {
  margin-bottom: 0px !important;
}

.Split-Flex {
  justify-content: space-between !important;
  align-items: center !important;
}

.Task-Card-Item-Inner-Row-Split {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Task-Card-Item-Inner-Row {
  display: flex;
  align-items: center;
  font-size: 1rem;
}
.modal-padding {
  padding: 10px;
}
.center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.Task-Card-Item-Description {
  align-items: flex-start;
  flex-direction: column;
}

.Task-Card-Item-Row-Split {
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 180px;
  margin-right: 10px;
}

.Task-Card-Item-Row-Extended {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 20px;
}
.Task-Card-Item-Row-Item-Extended {
  width: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.full-task-link {
  margin-left: 10px;
  padding-top: 4px;
}

@media only screen and (max-width: 1250px) {
  .Task-Card-Item-Row-Extended {
    justify-content: flex-start;
  }
  .Task-Card-Item-Row-Item-Extended {
    min-width: 140px;
    display: flex;
    justify-content: flex-start;
  }
}

.Task-Card-3-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Task-Card-Item-Row-Split-3-col {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.Task-Card-Item-Row-Split-3-col:nth-child(2) {
  align-items: center;
}
.Task-Card-Item-Row-Split-3-col:last-child {
  align-items: flex-end;
}

.Task-Card-Title-Text {
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: nowrap;
}
.Task-Card-Footer-Container {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-shadow: 0px -10px 10px -10px #424242;
  background-color: #f1f1f1;
  height: var(--task-card-footer-height);
}

.task-card-footer-container-dark {
  background-color: #404040;
}

.Footer-Pull-Left {
  right: 10px;
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}

@media screen and (max-width: 428px) {
  :root {
    /* Increased on mobile */
    --icon-size: 30px;
  }
  .Task-Card-Container {
    width: 100%;
  }
  .Task-Drawer-Container {
    max-width: 100vw;
  }
}

.slide {
  animation: slideDownFromTop 0.8s alternate;
}

@keyframes slideDownFromTop {
  0% {
    opacity: 0;
    height: 32px;
  }
  10% {
    height: 10%;
    opacity: 0.1;
  }
  20% {
    height: 20%;
    opacity: 0.2;
  }
  30% {
    height: 30%;
    opacity: 0.3;
  }
  40% {
    height: 40%;
    opacity: 0.4;
  }
  50% {
    height: 50%;
    opacity: 0.5;
  }
  60% {
    height: 60%;
    opacity: 0.6;
  }
  70% {
    height: 70%;
    opacity: 0.7;
  }
  80% {
    height: 80%;
    opacity: 0.8;
  }
  90% {
    height: 90%;
    opacity: 0.9;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
