.Icon-Only-Button {
  min-width: 44px !important;
  min-height: 44px !important;
  max-width: 44px !important;
  max-height: 44px !important;
  padding: 0 !important;
  margin: 0 !important;
}
.Icon-Only-Button .MuiSvgIcon-root {
  padding: 0 !important;
  margin: 0 !important;
  min-width: 44px !important;
  min-height: 44px !important;
  max-width: 44px !important;
  max-height: 44px !important;
  font-size: 44px !important;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6));
}
