:root {
  --pagenation-attribute-height: 35px;
}

.pagenation-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 350px;
  text-align: center;
  padding: 0 !important;
  gap: 10px;
  position: relative;
}

.bottom-row {
  gap: 1rem;
}

.row-end {
  position: absolute;
  right: 0px;
}

.pagenation-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between !important;
  align-items: center;
  column-gap: 10px;
}

.pagenation-button-group {
  height: var(--pagenation-attribute-height);
}

.pagenation-button {
  height: var(--pagenation-attribute-height);
  box-sizing: border-box;
  width: fit-content;
  box-shadow: none;
}
.pagenation-button-text {
  text-transform: none;
  font-weight: bold;
  white-space: nowrap;
}

.pagenation-date-picker {
  border-color: var(--mrr-dark-blue);
  outline-color: var(--mrr-dark-blue);
  height: var(--pagenation-attribute-height) !important;
}
.pagenation-date-picker-input .MuiInputBase-root input {
  height: var(--pagenation-attribute-height) !important;
}
.pagenation-date-picker-input .MuiInputBase-root {
  height: var(--pagenation-attribute-height) !important;
}

.pagenation-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  padding-bottom: 5px;
}

.pagenation-select {
  height: var(--pagenation-attribute-height);
  box-sizing: border-box;
  text-align: center;
  width: 86px;
  border-color: var(--mrr-dark-blue);
  outline-color: var(--mrr-dark-blue);
}

@media only screen and (max-width: 767px) {
  .pagenation-768px-991px {
    display: none;
  }
  .pagenation-above-992px {
    display: none;
  }
  .pagenation-below-767px {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
  }
  .pagenation-date-picker,
  .pagenation-date-picker-input .MuiInputBase-root {
    width: 190px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pagenation-768px-991px {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
  }
  .pagenation-below-767px {
    display: none;
  }
  .pagenation-above-992px {
    display: none;
  }
  .pagenation-date-picker,
  .pagenation-date-picker-input .MuiInputBase-root {
    width: 263px !important;
  }
}

@media only screen and (min-width: 992px) {
  .pagenation-below-767px {
    display: none;
  }
  .pagenation-768px-991px {
    display: none;
  }
  .pagenation-above-992px {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
  }
  .pagenation-date-picker,
  .pagenation-date-picker-input .MuiInputBase-root {
    width: 245px !important;
  }
}

.filter-modal {
  max-width: 400px;
  min-width: 350px;
  width: 100%;
}
.filter-modal-body {
  min-height: 400px;
}
