.List_Group_Label {
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  display: block !important;
  margin: 0px !important;
  padding: 0px !important;
  text-decoration: none !important;
  height: 100% !important;
  width: 100% !important;
  touch-action: manipulation !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
}
