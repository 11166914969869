.App-Bar {
  position: absolute;
  height: 50px;
  margin: 0;
  width: 100vw;
  display: flex;
  background-image: none !important;
}

.App-Bar-Avatar {
  width: 35px;
  height: 35px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-margin-and-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.App-Bar-Inner-Container {
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 10px;
}

.MRR_Logo_topAppBar-img {
  height: 40px;
  width: 350px;
  box-sizing: content-box;
  padding: auto;
}

/* .App-Sidebar-Toggle {
  margin: 0 !important;
  padding: 0 !important;
}
.App-Sidebar-Toggle-Btn {
  padding: 0 !important;
  margin: 0 !important;
} */

/* .MRR_Logo_topAppBar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MRR_Logo_topAppBar-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MRR_Logo_topAppBar-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Profile-SideBar-Toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}
*/
