.Listing-Page_Container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.Listing-Page_Header {
}
.Listing-Page_Header-Content {
  display: flex;
  width: 100%;
}

.Listing-Task_Container {
  display: flex;
  flex-direction: column;
}

.Task-List {
  display: flex;
  flex-direction: column;
}

.Listing-Page_Pagenation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.Task-Messages-Container {
  padding-top: 10px;
  min-width: 350px;
  width: 100%;
}

.Listing-Section-Title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.Filter_By_Duedate_Time-Inner-Button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ListingPageError {
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ListingPageLoading {
  width: 100%;
  display: flex;
  height: 175px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

@media only screen and (min-width: 1026px) {
  .Listing-Task_Container {
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 10px;
  }
  .Listing-Page-Section {
    width: 100%;
  }
}
