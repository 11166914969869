:root {
  --password-form-padding: 20px;
  --button-height: 35px;
}

.forgot_password-Modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot_password-Paper {
  box-shadow: 3px 2px var(--mrr-light-blue) !important;
  min-width: 300px;
  max-width: 350px;
}

.forgot_password-Form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--password-form-padding);
}

.forgot_password-button-group {
  display: flex;
  width: 100%;
  justify-content: space-around;
  border-top: 1px solid lightgray;
  height: var(--button-height);
  margin: 20px 0;
}

.forgot_password-button-group button {
  height: var(--button-height);
}

.helper-Text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.forgot_password-Input {
  width: 100%;
}

.forgot_password-Submit {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
