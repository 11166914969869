:root {
  --card-spacing: 10px;
  --card-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  --card-focus-shadow: 0px 4px 5px -2px rgb(0 0 0 / 20%),
    0px 7px 10px 1px rgb(0 0 0 / 14%), 0px 2px 16px 1px rgb(0 0 0 / 12%);
}

.Compact-Card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: var(--card-spacing);
  font-size: 1rem;
  min-height: 75px;
  height: auto;
  width: auto;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  margin-bottom: var(--card-spacing);
  box-shadow: var(--card-shadow) !important;
}

.card-variant-warning {
  border-left: 4px solid darkred;
}

.card-variant-primary {
  border-left: 4px solid var(--mrr-dark-blue);
}
.card-variant-secondary {
  border-left: 4px solid var(--mrr-light-blue);
}

.card-variant-fade {
  opacity: 0.5;
}

.Compact-Card:hover {
  box-shadow: var(--card-focus-shadow) !important;
}
.Compact-Card:active {
  box-shadow: var(--card-focus-shadow) !important;
  background-color: #f2f4f4;
}

/* ----- CompactCardInfoHeader ----- */
.Compact-Card-Info-Header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* gap: var(--card-spacing); */
}

.Compact-Card-Info-Status {
  white-space: nowrap;
}
