.CompactCard-Skeleton-Container {
  width: 100%;
  height: 120px !important;
  padding: 10px !important;
  margin: 0 !important;
  box-sizing: border-box;
  overflow: hidden;
}
.CompactCard-Skeleton-row {
  min-width: 275px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}
.CompactCard-Skeleton-sm-item {
  width: 100px;
  height: 25px;
}
.CompactCard-Skeleton-md-item {
  width: 150px;
  height: 25px;
}
.CompactCard-Skeleton-lg-item {
  width: 200px;
  height: 25px;
}

@media only screen and (max-width: 400px) {
  .CompactCard-Skeleton-sm-item {
    width: 100px;
    height: 25px;
  }
  .CompactCard-Skeleton-md-item {
    width: 150px;
    height: 25px;
  }
  .CompactCard-Skeleton-lg-item {
    width: 200px;
    height: 25px;
  }
}
@media only screen and (max-width: 800px) and (min-width: 401px) {
  .CompactCard-Skeleton-sm-item {
    width: 120px;
    height: 25px;
  }
  .CompactCard-Skeleton-md-item {
    width: 170px;
    height: 25px;
  }
  .CompactCard-Skeleton-lg-item {
    width: 200px;
    height: 25px;
  }
}
@media only screen and (min-width: 801px) {
  .CompactCard-Skeleton-sm-item {
    width: 200px;
    height: 25px;
  }
  .CompactCard-Skeleton-md-item {
    width: 300px;
    height: 25px;
  }
  .CompactCard-Skeleton-lg-item {
    width: 400px;
    height: 25px;
  }
}
