*::before,
*::after,
* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

:root {
  --main-bg-color: "#ffffff";
  --secondary-bg-color: "#efefef";
  --shadow: hsla(0, 0%, 0%, 0.2);
  --main-text-color: #404040;
  --secondary-text-color: hsl(0, 0%, 30%);
  --airbnb-red: hsl(358, 100%, 68%);
  --airbnb-blue: hsl(175, 100%, 33%);
  --airbnb-orange: hsl(16, 97%, 58%);
  --airbnb-gray: hsl(0, 0%, 28%);
  --airbnb-lg-gray: hsl(0, 0%, 46%);
  --button-hover-color: hsl(217, 46%, 48%);
  --button-bg-color: white;
  --modal-fg-color: white;
  --modal-bg-color: rgba(0, 0, 0, 0.1);
  --main-header-color: hsl(175, 100%, 33%);
  --mrr-dark-blue: #1b3664;
  --mrr-lime-green: #70bd65;
  --mrr-light-blue: #5994c5;
  --mrr-lime-green-2: #589450;
}

@media screen and (max-width: 768px) {
  .touch {
    -webkit-overflow-scrolling: touch;
  }
}

html {
  font-size: 14;
}

body {
  font-family: "Heebo", "Roboto", sans-serif;
  margin: 0;
  color: "#404040";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spin-me {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

b {
  font-weight: 800;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--main-text-color);
}

strong {
  font-weight: 800;
}
