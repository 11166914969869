.modal-paper {
  position: absolute;
  border-radius: 10px !important;
  width: 428px;
  min-height: 60vh;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--mrr-dark-blue);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #efefef;
  box-shadow: 0 2px 5px 0px gray;
  height: 65px;
}

.modal-content {
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.check_in_request-item:not(.details) {
  list-style: decimal;
  margin-left: 20px;
}
.details {
  list-style: none;
  margin-right: 10px;
}

@media screen and (max-width: 428px) {
  .modal-paper {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
  .modal-header {
    border-radius: 0;
  }
  .check_in_request-item:not(.details) {
    list-style: decimal;
    margin-left: 25px;
  }
  .modal-content {
    height: fit-content;
    grid-template-columns: 1fr;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
