.Teams_User_Card-Paper {
  min-height: 175px !important;
  min-width: 345px !important;
  display: flex !important;
  justify-content: space-evenly !important;
  padding: 20px;
}

.Basic_User_Info-Container {
  width: 110px !important;
  min-height: 100px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.User_Contact_Info-Container a {
  word-break: break-all;
}

.User_Profile_Photo {
  background-color: lightgray;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100px !important;
  width: 100px !important;
}

.Phone-Container {
  display: flex !important;
  justify-content: space-between !important;
  max-width: 200px !important;
}
.Phone-Container_Right {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}
.Phone-Container_Left {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}

.Loading-Users-Container {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  justify-content: space-between;
}
