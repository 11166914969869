.editor-text-underline {
  text-decoration: underline;
}
.editor-text-italic {
  font-style: italic;
}
strong.editor-text-bold {
  font-weight: bold;
}

.editor-text-strikethrough {
  text-decoration: line-through;
  text-decoration-style: solid;
  text-decoration-color: inherit;
}

.editor-quote {
  quotes: "“" "”" "‘" "’";
}
.editor-quote::before {
  content: open-quote;
}
.editor-quote::after {
  content: close-quote;
}
.editor-link span {
  text-decoration: underline;
  color: var(--mrr-dark-blue);
}
.editor-link {
  text-decoration: underline;
  color: var(--mrr-dark-blue);
}

.profile_Avatar-small {
  max-width: 2rem;
  max-height: 2rem;
}
.profile_Avatar-auto {
  width: 35px;
  height: 35px;
}
.profile_Avatar {
  max-width: 30px;
  max-height: 30px;
  margin: 5px;
  margin-top: 0;
}

.messages_editor_Box {
  width: calc(100% - 40px);
}

.editor-outerContainer {
  border: 1px solid lightgray;
  border-radius: 4px;
}

.editor-inner-container {
  display: flex;
  flex-direction: column;
}

.editor-input {
  /* background-color: pink; */
  overflow-y: auto;
  height: 5rem;
  line-height: 16px;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
}
.editor-input:focus-visible {
  outline: none;
}

.editor-placeholder-container {
  position: relative;
}
.editor-placeholder {
  position: absolute;
  margin-top: -4.8rem;
  padding: 10px;
  z-index: -1;
}

.editor-Toolbar {
  border-bottom: 1px solid lightgray;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.02);
}

.editor-FormatButton_container {
  height: auto;
  width: auto;
}

.message_created_at_Container {
  display: flex;
  justify-content: flex-end;
}

.message_body p {
  line-height: 14px;
  display: inline-block;
  margin: 0;
}

.message_body b {
  font-weight: bold;
}

.message_body a {
  color: var(--mrr-dark-blue) !important;
}

.message_body ol,
ul {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  padding-inline-start: 1em;
}

.message_body li {
  text-align: start;
  width: fit-content;
}
.Task-Messages-Container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
}
