.Task-Page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.Task-Page-Header {
  height: 100%;
  width: 100%;
}

.Task-Page-Split-Container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  margin-bottom: 60px;
}

@media only screen and (min-width: 856px) {
  .Task-Page-Split-Container {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
  }
  .Task-Page-Header {
    width: 100%;
  }
  .Task-Page-Section {
    flex: 1;
  }
}

.Task-Page-Footer-Container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.Task-Page-Footer-Right {
  display: flex;
  column-gap: 10px;
}
