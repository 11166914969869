#TaskSort_Menu {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}
#TaskSort_Mobile_Menu_Item {
  width: 100px;
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;
}
#TaskSort_FullWidth_Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
}
