.Multi_Media-Card {
  margin-right: 2 !important;
  margin-bottom: 5px !important;
  width: 100px !important;
  height: 75px !important;
}

.Multi-Media-Card-Inner {
  width: 100px !important;
  height: 75px !important;
  display: flex !important;
  position: relative !important;
  justify-content: center !important;
  align-items: center !important;
}
.Multi-Media-Card-Row {
  width: 100%;
  max-height: 1px;
}
.Multi_Media-Card-Container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.Multi_Media-Card-Outter-Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 0;
}
.Multi_Media-Card-Outter-Container-Border {
  border-top: 1px solid lightgray;
  width: 100%;
}
