.Center-Container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
}
.Unknown-Page_Container {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 350px !important;
  max-width: 428px !important;
  width: 100%;
  padding: 10px;
}
