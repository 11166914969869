.Tabs-Container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Tabs-Switch-Container {
  border-bottom: 1px solid lightgray;
}
.Tab-Container {
  display: flex;
  flex-direction: column;
}
.Tab-Inner-Container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 10px;
}
.Tab {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.App_Tabs-Pagination-Container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.Tabs-Container .card-variant-fade {
  opacity: 1;
}
