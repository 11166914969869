.User-Page-Container {
  min-width: 320px;
  max-width: 500px;
  width: 100%;
}

.User-Page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.User-Info-Card {
  min-width: 320px;
  max-width: 500px;
  width: 100%;
  margin-top: 10px;
}

.User-Info-Grid {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.User-Info-Grid-Item {
  display: flex;
  position: relative;
}

.center {
  align-items: center;
  justify-content: center;
}

.col {
  flex-direction: column;
}

.User-Page-Groups-Container {
  width: 100%;
  min-width: 320px;
  max-width: 500px;
  min-height: 50px;
}

.User-Profile-Card {
  width: 100%;
}

.User-Profile-Card img {
  width: 200px;
  aspect-ratio: 1/1;
  background-size: 200px 200px;
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  background-color: lightgray;
  margin-bottom: 10px;
}

.Edit-Container-Button {
  position: absolute;
  right: 0px;
  top: 3px;
  z-index: 1200;
}

.Edit-Button * {
  font-size: x-large !important;
}
