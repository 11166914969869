.Task-Form-Label {
  white-space: nowrap;
  color: rgb(64, 64, 64);
  font-size: 16px;
}

.Task-Form-Label-Dark {
  color: #efefef;
}

.Task-Form-Input-Row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Task-Form-Default {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit_task_title_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit_task_related {
  display: block;
}

.flex-row {
  display: flex;
  width: 100%;
}
